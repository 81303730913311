import { library, config } from "@fortawesome/fontawesome-svg-core"
import {
  faMale,
  faFemale,
  faEye,
  faWeight,
  faXRay,
  faHeartbeat,
  faTint,
  faUserMd,
  faCheck,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons"

library.add(
  faMale,
  faFemale,
  faEye,
  faWeight,
  faXRay,
  faHeartbeat,
  faTint,
  faUserMd,
  faCheck,
  faUserFriends
)

config.autoAddCss = false /* eslint-disable import/first */
