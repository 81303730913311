import React, { useEffect, useState } from "react"
import { Col, Row, Modal, Button } from "react-bootstrap"

export default function ExitForm() {
  const [formState, setFormState] = useState("initial")
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    // the handler for actually showing the prompt
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
    const handler = event => {
      handleShow()
    }
    // if the form is NOT unchanged, then set the onbeforeunload
    if (formState === "initial") {
      document.addEventListener("mouseleave", handler)
      // clean it up, if the dirty state changes
      return () => {
        document.removeEventListener("mouseleave", handler)
      }
    }
    // since this is not dirty, don't do anything
    return () => {}
  }, [formState])

  if (formState === "succeeded") {
    return <p>Thanks for joining!</p>
  }
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      centered
      dialogClassName="modal-90w"
    >
      <div class="register-photo">
        <div class="form-container">
          <div class="image-holder"></div>
          <form
            id="exit-form"
            action="https://formspree.io/f/xvoyrwnk"
            method="post"
          >
            <h2 class="text-center">
              <strong>Take care</strong> of your health.
            </h2>
            <p class="text-center mb-4">
              Health is wealth. Be the first to receive promotions and discounts
              on screening and health packages. Subscribe below to keep updated
              on health news relevant to you.
            </p>
            <div class="form-group">
              <input
                class="form-control"
                type="email"
                name="email"
                placeholder="Email"
              />
            </div>
            <div class="form-group">
              <button class="btn btn-primary btn-block" type="submit">
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
}
