import React from "react"
import { Carousel, Card } from "react-bootstrap"

export default function AdBanner({ node }) {
  return (
    <Carousel>
      <Carousel.Item>
        <a href="https://invle.co/cle4liq?url=https%3A%2F%2Fshopee.com.my%2Funiversal-link%2F">
          <img
            className="d-block w-100"
            src="/omron-banner-1.jpeg"
            alt="Blood pressure and monitor"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a href="https://invle.co/cle4liq?url=https%3A%2F%2Fshopee.com.my%2Funiversal-link%2F">
          <img
            className="d-block w-100"
            src="/omron-banner-2.jpeg"
            alt="Scale body and fat analyzer"
            href="https://invle.co/cle4liq?url=https%3A%2F%2Fshopee.com.my%2Funiversal-link%2F"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a href="https://invle.co/cle4liq?url=https%3A%2F%2Fshopee.com.my%2Funiversal-link%2F">
          <img
            className="d-block w-100"
            src="/omron-banner-3.jpeg"
            alt="Nebulizer and aspirator"
            href="https://invle.co/cle4liq?url=https%3A%2F%2Fshopee.com.my%2Funiversal-link%2F"
          />
        </a>
      </Carousel.Item>
    </Carousel>
  )
}
