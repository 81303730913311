import React from "react"
import { Navbar, Container, Nav } from "react-bootstrap"
import ExitForm from "./exitForm"

import "../utils/fontawesome"

function Layout({ children, title, description }) {
  return (
    <div>
      <ExitForm />
      <div className="code-dryer-banner">
        <div className="code-dryer-banner-content">
          This website is built for FREE with{" "}
          <a target="_blank" href="https://www.codedryer.com">
            Code Dryer
          </a>
        </div>
      </div>
      <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
        <Container>
          <Navbar.Brand href="/" className="mr-0">
            <img
              src="/logo.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="MedicalCheckUp.MY logo"
            />{" "}
            Medical Check Up Malaysia
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Screening Packages</Nav.Link>
              <Nav.Link href="/hospitals/private">Hospitals</Nav.Link>
            </Nav>
            <div className="twitter-follow-author" align="right">
              <a
                href="https://twitter.com/shahnawaskhan?ref_src=twsrc%5Etfw"
                class="twitter-follow-button"
                data-size="large"
                data-show-screen-name="false"
                data-show-count="false"
              >
                Follow
              </a>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {children}
    </div>
  )
}

export default Layout
